import * as amplitude from '@amplitude/analytics-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';
import { useSession } from 'next-auth/react';
import { createContext, ReactNode, useContext, useEffect } from 'react';

interface AmplitudeAnalyticsContextData {
  setUser: (id: string) => void;
  trackEvent: (name: string, data?: Record<string, unknown>) => void;
}

interface AmplitudeAnalyticsContextProviderProps {
  children: ReactNode;
  amplitudeApiKey: string | undefined;
}

export const AmplitudeAnalyticsContext = createContext(
  {} as AmplitudeAnalyticsContextData,
);

export function AmplitudeAnalyticsContextProvider({
  children,
  amplitudeApiKey,
}: AmplitudeAnalyticsContextProviderProps) {
  const session = useSession();

  function setUser(id: string) {
    const { data } = session;

    if (!data || !data.user) {
      return;
    }

    const identifyEvent = new amplitude.Identify();

    Object.entries(data.user).forEach(([key, value]) => {
      if (!value) return;
      identifyEvent.set(key, value);
    });

    amplitude.setUserId(id);
    amplitude.identify(identifyEvent);
  }

  function trackEvent(name: string, data?: Record<string, unknown>) {
    amplitude.track(name, data);
  }

  useEffect(() => {
    if (!amplitudeApiKey || process.env.NODE_ENV !== 'production') {
      return;
    }

    const sessionReplayTracking = sessionReplayPlugin({ sampleRate: 1 });
    amplitude.add(sessionReplayTracking);

    amplitude.init(amplitudeApiKey, {
      autocapture: {
        elementInteractions: true,
      },
    });

    if (session.data?.user) {
      setUser(session.data.user.email);
    }
  }, [amplitudeApiKey, session.data?.user]);

  return (
    <AmplitudeAnalyticsContext.Provider
      value={{
        setUser,
        trackEvent,
      }}
    >
      {children}
    </AmplitudeAnalyticsContext.Provider>
  );
}

export const useAmplitudeAnalytics = () =>
  useContext(AmplitudeAnalyticsContext);
