declare global {
  interface Window {
    // eslint-disable-next-line
    fbq: any;
  }
}

/**
 * https://www.facebook.com/business/help/402791146561655?id=12053766828321421
 */
type EventName =
  | 'ViewContent'
  | 'Search'
  | 'Purchase'
  | 'AddToCart'
  | 'AddToWishlist'
  | 'CompleteRegistration'
  | 'AddPaymentInfo'
  | 'InitiateCheckout'
  | 'Lead';

export const pageview = () => {
  /**
   * Won't exist in develop
   */
  if (window?.fbq) {
    window.fbq('track', 'PageView');
  }
};

// https://developers.facebook.com/docs/facebook-pixel/advanced/
// https://developers.facebook.com/docs/meta-pixel/reference
export const event = (name: EventName, options = {}) => {
  /**
   * Won't exist in develop
   */
  if (window?.fbq) {
    window.fbq('track', name, options);
  }
};
