import { extendTheme } from '@chakra-ui/react';

export const theme = extendTheme({
  colors: {
    brand: {
      blue: '#1C45D6',
      purple: '#7903EF',
      lightBlue: '#F0F2FF',
    },
  },
  fonts: {
    heading: 'Nunito',
    body: 'Nunito',
  },
  styles: {
    global: {
      body: {
        bg: '#fff',
        color: '#000',
      },
    },
  },
  components: {
    Progress: {
      baseStyle: {
        filledTrack: {
          opacity: 0.8,
          bgGradient: 'linear(to-r, brand.blue, brand.purple)',
        },
      },
    },
  },
});
